import React from "react";
function Home() {
  return (
    <div >
    
    <div className="container mx-auto flex flex-col  pt-64 items-center">
    <h1 className="title-font sm:text-2xl text-3xl mb-4 font-medium text-white">HI THERE</h1>
    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white"> I'm ANAND MADHAVAN </h1>
</div>
    
    </div>
   
    
  );
}

export default Home;